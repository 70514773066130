<template>
    <div class="order">
      <div class="page-header">
        <h1>{{ $t('Service Order Management') }} > {{ $t('Orders') }} > {{ $t('Add') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Add Order') }}</h2>
        </div>
        <OrderForm @action="addOrder"/>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import ServiceOrder from '@/lib/serviceOrder';
  import OrderForm from '@/components/service/OrderForm.vue';
  export default {
    name: 'CreateServiceOrder',
    components: {
      OrderForm
    },
    methods:{
      async addOrder(form){
        try{
          const loginInfo = Common.getLoginInfo();
          const order = await ServiceOrder.createOrder(this.apiUrl, form, loginInfo);
          this.$router.push('/services/orders');
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: mapState({
     langcode: state => state.langcode,
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  